// /var/www/website2024/dev.afaa.website/s-293-spatime-dev/src/pages/contact.js
import React from 'react';
import { Card } from 'antd';
import styles from '/var/www/afra/dev.afaa.website/s-293-spatime-dev/src/styles/Contact.module.scss';

// Import the JSON data
import branches from '../data/branches.json';

const Contact = () => {
  return (
    <div className={styles.contactPage}>
      <div className={styles.contactContainer}>
        <h1> ISAAN THAÏ SPA </h1>
        <h2> CONTACT </h2>
        <div className={styles.cardsContainer}>
          {branches.map((branch) => (
            <Card key={branch.id} title={branch.branch} className={styles.branchCard}>
              <p><strong>Address:</strong> {branch.address}</p>
              <p><strong>Tél:</strong> {branch.phone}</p>
            </Card>
          ))}
        </div>
        {/* Include a contact form or more contact information here */}
      </div>
    </div>
  );
}

export default Contact;
